<template>
    <div class="package-detail-container">
        <div class="top">
            <Header :title="'课程包'" :bgColor="'#00000000'" :leftArrowType="1" :titleColor="'#ffffff'" :leftClick="goBack">
            </Header>
            <div style="
          display: flex;
          width: 100%;
          padding: 0px 15px;
          box-sizing: border-box;
        ">
                <div>
                    <div v-if="checkCollectionCover() == 1">
                        <img :src="collectionInfo.album_contents[0].cover_pic_url" class="fistImg" />
                    </div>
                    <div v-else class="collectionImg">
                        <img v-for="i in 4" :key="i" :src="collectionInfo.album_contents[i - 1].cover_pic_url" />
                    </div>
                </div>
                <div style="
            width: 100%;
            flex: 1;
            padding-left: 15px;
            box-sizing: border-box;
          ">
                    <p style="color: white; font-size: 18px">
                        {{ collectionInfo.collection_name }}
                    </p>
                    <p style="
              color: white;
              font-size: 14px;
              line-height: 18px;
              margin-top: 10px;
            ">
                        {{ collectionInfo.collection_intro }}
                    </p>

                    <div style="margin-top: 10px; display: flex; flex-wrap: wrap">
                        <p class="tag-style">
                            共{{ collectionInfo.album_contents.length }}个专辑
                        </p>
                        <p class="tag-style">共{{ totalAudioCounts }}个音频</p>
                        <p class="tag-style">预估{{ totalKeshi }}个课时</p>
                        <p class="tag-style">预计学习{{ totalWeeks }}周</p>
                    </div>
                </div>
            </div>
            <div style="height: 1px; background-color: white; margin: 10px 15px"></div>
            <div style="
          color: white;
          font-size: 16px;
          width: 100%;
          padding: 0px 15px;
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
        ">
                分类：
                <div v-for="(tag, index) in collectionInfo.collection_labels" :key="index">
                    <p v-if="tag && tag != ''" class="tag1-style"> {{ tag }}</p>
                </div>
            </div>
        </div>
        <div class="package-content">
            <div style="flex: 1;overflow-y: auto;">
                <div style="display: flex; width: 100%; align-items: center">
                    <div style="flex: 1; text-align: center; cursor: pointer" @click="currentTab = 0">
                        <img v-if="currentTab == 0" src="../../assets/app/iv_album_tab_kecheng_selected.png"
                            style="width: 72px; height: auto; object-fit: cover" />
                        <img v-else src="../../assets/app/iv_album_tab_kecheng_unselected.png"
                            style="width: 72px; height: auto; object-fit: cover" />
                    </div>
                    <div style="flex: 1; text-align: center; cursor: pointer" @click="currentTab = 1">
                        <img v-if="currentTab == 1" src="../../assets/app/iv_album_tab_content_selected.png"
                            style="width: 64px; height: auto; object-fit: cover" />
                        <img v-else src="../../assets/app/iv_album_tab_content_unselected.png"
                            style="width: 64px; height: auto; object-fit: cover" />
                    </div>
                    <div style="flex: 1; text-align: center; cursor: pointer" @click="currentTab = 2">
                        <img v-if="currentTab == 2" src="../../assets/app/iv_album_tab_parent_selected.png"
                            style="width: 64px; height: auto; object-fit: cover" />
                        <img v-else src="../../assets/app/iv_album_tab_parent_unselected.png"
                            style="width: 64px; height: auto; object-fit: cover" />
                    </div>
                </div>

                <div v-if="currentTab == 0" style="margin-top: 15px">
                    <div v-for="(content, index) in collectionInfo.simple_intro_paragraphs" :key="index"
                        style="margin-bottom: 5px">
                        <p style="
                color: #454651;
                font-size: 14px;
                line-height: 22px;
                text-indent: 2rem;
              ">
                            {{ content }}
                        </p>
                    </div>
                </div>
                <div v-if="currentTab == 1" style="margin-top: 15px">

                    <div style="text-align: center;margin-bottom: 15px;">
                        <img src="../../assets/app/add_audio_hint_package.png"
                            style="width: 80%;height: auto;object-fit: cover;">
                    </div>

                    <div v-for="(content, index) in collectionInfo.album_contents" :key="index" class="album-item">
                        <img :src="content.cover_pic_url" style="
                width: 64px;
                height: 64px;
                object-fit: cover;
                border-radius: 12px;
              " />
                        <div style="
                width: 100%;
                padding-left: 15px;
                flex: 1;
                box-sizing: border-box;
              ">
                            <p style="color: #333333; font-size: 16px">
                                {{ content.album_name }}
                            </p>
                            <p style="color: #666666; font-size: 14px; margin-top: 8px">
                                {{ content.album_name_sub }}
                            </p>
                            <div style="margin-top: 8px; display: flex; align-items: center">
                                <el-progress :percentage="formatProgress(content)" :show-text="false"
                                    style="flex: 1; padding-right: 10px; box-sizing: border-box"></el-progress>
                                <img src="../../assets/app/ic_locker.png"
                                    style="width: 28px; height: 28px; object-fit: cover" />
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="currentTab == 2" style="margin-top: 15px">
                    <div style="display: flex; align-items: center">
                        <img src="../../assets/app/songs2.png" style="width: 30px; height: auto" />
                        <p style="
                color: #333333;
                font-size: 18px;
                margin-left: 10px;
                margin-top: 5px;
              ">
                            课程特点
                        </p>
                    </div>
                    <div style="margin-top: 10px">
                        <div v-for="(content, index) in collectionInfo.highlights_paragraphs" :key="index">
                            <p style="
                  color: #454651;
                  font-size: 14px;
                  text-indent: 2rem;
                  margin-bottom: 8px;
                  line-height: 22px;
                ">
                                {{ content }}
                            </p>
                        </div>
                    </div>
                    <div style="display: flex; align-items: center; margin-top: 10px">
                        <img src="../../assets/app/songs3.png" style="width: 30px; height: auto" />
                        <p style="
                color: #333333;
                font-size: 18px;
                margin-left: 10px;
                margin-top: 5px;
              ">
                            学习收获
                        </p>
                    </div>
                    <div style="margin-top: 10px">
                        <div v-for="(content, index) in collectionInfo.values_paragraphs" :key="index">
                            <p style="
                  color: #454651;
                  font-size: 14px;
                  text-indent: 2rem;
                  margin-bottom: 8px;
                  line-height: 22px;
                ">
                                {{ content }}
                            </p>
                        </div>
                    </div>
                    <div style="display: flex; align-items: center; margin-top: 10px">
                        <img src="../../assets/app/songs4.png" style="width: 30px; height: auto" />
                        <p style="
                color: #333333;
                font-size: 18px;
                margin-left: 10px;
                margin-top: 5px;
              ">
                            适合孩子
                        </p>
                    </div>
                    <div style="margin-top: 10px">
                        <div v-for="(content, index) in collectionInfo.targets_paragraphs" :key="index">
                            <p style="
                  color: #454651;
                  font-size: 14px;
                  text-indent: 2rem;
                  margin-bottom: 8px;
                  line-height: 22px;
                ">
                                {{ content }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style="width: 100%;padding: 12px;box-sizing: border-box;background-color: #686CC0;display: flex;color: white;border-radius: 6px;box-sizing: border-box;">
                <div style="flex: 1;width: 100%;display: flex;flex-direction: column;">
                    <p style="font-size: 14px;">还差1人拼团</p>
                    <div class="bottom-left-container">
                        <div style="flex: 1;color: #333;font-size: 11px;width: 100%;text-align: center;">
                            <p>原价：{{ collectionInfo.collection_price_show }}</p>
                            <p style="margin-top: 2px;">拼团：{{ groupPayPoints
                            }}</p>
                        </div>
                        <div
                            style="font-weight: bold;text-align: center;flex: 1;height: 100%;line-height: 42px;background-color: orange;border-radius: 8px;color: white;font-size: 14px;">
                            省：{{ reductionPriceVaule }}
                        </div>
                    </div>
                </div>
                <div
                    style="flex: 1;width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;">
                    <p style="font-size: 14px;">{{ groupPurchaseCountDownText }}</p>
                    <div class="btn-groupPurchase" @click="checkGroupPurchase()">参与拼团</div>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="showPointsNotEnoughDialog" :title="pointsNotEnoughTitle" width="85%">
            <p style="line-height: 22px;">{{ pointsNotEnoughContent }}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showPointsNotEnoughDialog = false">取消</el-button>
                <el-button type="primary" @click="gotoPurchasePoints">购买可能点</el-button>
            </span>
        </el-dialog>

        <el-dialog :visible.sync="showGroupPurchaseDialog" title="拼团购买" width="85%">
            <p style="line-height: 22px;color: #333;">{{ '课程包：' + collectionInfo.collection_name }}</p>
            <p style="line-height: 22px;color: #333;">消耗可能点：{{ groupPayPoints
            }}</p>
            <p style="line-height: 22px;color: #333;">剩余可用可能点{{ userPoints - groupPayPoints
            }}
            </p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showGroupPurchaseDialog = false">取 消</el-button>
                <el-button type="primary" @click="doGroupPurchase">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog :visible.sync="showPurchaseSuccessDialog" title="提示" width="95%" :show-close="false">
            <p style="line-height: 22px;">{{ purchaseSuccessHint }}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="gotoPackageDetail">待会设置</el-button>
                <el-button type="primary" @click="gotoScheduleTab">现在设置</el-button>
            </span>
        </el-dialog>

        <el-dialog :visible.sync="showLoginDialog" title="请先登录" width="95%">
            <div>
                <el-input v-model="userMobile" placeholder="请输入手机号码"></el-input>
                <p style="text-align: right;color: #5d5bb5;margin-top: 10px;">新号码将自动注册为新用户</p>
                <div style="margin-top: 20px; position: relative; width: 100%; height: 42px">
                    <div style="position: absolute; width: 100%">
                        <el-input v-model="loginCode" type="number" placeholder="请输入验证码" />
                    </div>
                    <p class="login-code" @click="getCode">{{ codeInfo }}</p>
                </div>

                <el-button type="primary" style="margin-top: 20px;" @click="doLogin">登录</el-button>
            </div>

        </el-dialog>
    </div>
</template>
<script>
import {
    getDefaultKid,
    checkUserHasApplyCollection,
    getCollectionV1,
    getCollection, getGroupPurchaseInfo, finishGroupPurchase, getUserByUserID, particGroupPurchase, getMyPurchases
} from '../../api/app'
import {
    verifyLoginPin,
    createLoginPin,
    getOpenIdForFWH
} from '../../api/keneng'
import { setAppKidId, getOpenId, setOpenId, getAppUserId, getAppLoginToken, setHomeFragmentScheduleTabIndex, setHomeTabIndex, setAppLoginToken, setAppUserId } from '../../utils/store';
import { Loading } from "element-ui";
import Header from "../../components/app/Header.vue";
export default ({
    name: 'index',
    components: {
        Header,
    },
    data() {
        return {
            wx_code: this.$route.query.code,
            groupPurchaseId: this.$route.query.id,
            collectionInfo: {},
            totalAudioCounts: 0,
            totalKeshi: 0,
            totalWeeks: 0,
            currentTab: 0,
            userPoints: 0,
            showPointsNotEnoughDialog: false,
            showGroupPurchaseDialog: false,
            pointsNotEnoughTitle: '',
            pointsNotEnoughContent: '',

            groupPruchaseInfo: undefined,
            startGroupPurchaseCountDownTimer: undefined,
            groupPurchaseCountDownText: '',

            showScheduleTabIndex: 1,
            showPurchaseSuccessDialog: false,
            reductionPriceVaule: 0,
            purchaseSuccessHint: '',

            showLoginDialog: false,
            userMobile: '',
            loginCode: '',
            codeInfo: "获取验证码",
            countDown: undefined,
            TOTAL_SECONDS: 60,

            groupPayPoints: 0.0,
            normalPayPoints: 0.0,
        }
    },
    beforeDestroy() {
        if (this.countDown) {
            window.clearInterval(this.countDown);
        }
    },
    mounted() {
        // setOpenId('oOh4h54l3ebT0mNFm-7nMRyb3Nnw')
        this.fetchData()
        if (this.checkIsLogin()) {
            this.requestUserpoints()
        } else {
            this.$message.success('检测到您还未登录，请先登录～')
            this.showLoginDialog = true
            getOpenIdForFWH(this.wx_code).then((res) => {
                let open_id = res.data.data.openid;
                setOpenId(open_id);
            });
        }
    },
    methods: {

        async doLogin() {
            if (this.userMobile == '') {
                this.$message.warning('请输入手机号码')
                return
            }
            if (this.loginCode == '') {
                this.$message.warning('请输入短信验证码~')
                return
            }
            //换成app接口
            let loginResult = await verifyLoginPin(this.userMobile, this.loginCode, "", getOpenId())
            if (loginResult.data.code != 0) {
                this.$message.error(loginResult.data.msg)
                return
            }
            const user_id = loginResult.data.data._id.$id;
            const login_token = loginResult.data.data.login_token;
            setAppUserId(user_id);
            setAppLoginToken(login_token);
            let defaultKidIdResult = await getDefaultKid(user_id)
            setAppKidId(defaultKidIdResult.data.data._id.$id)

            this.$message.success('登录成功')
            this.fetchData()
            this.requestUserpoints()
            this.showLoginDialog = false
        },
        getCode() {

            if (this.userMobile == "") {
                this.$message.warning("请输入手机号码~");
                return;
            }
            if (false) {
                this.startCodeCountDown();
                return;
            }
            createLoginPin(this.userMobile).then((res) => {
                let result = res.data;
                if (result.Code && result.Code == "OK") {
                    this.$message.success("验证码发送成功");
                    //开始倒计时
                    this.startCodeCountDown();
                } else {
                    this.$message.error("发送过于频繁，请稍后重试！");
                }
            });
        },
        startCodeCountDown() {
            var totalSeconds = this.TOTAL_SECONDS + 1;
            this.countDown = setInterval(() => {
                totalSeconds--;
                if (totalSeconds <= 0) {
                    clearInterval(this.countDown);
                    this.codeInfo = "获取验证码";
                } else {
                    this.codeInfo = "倒计时" + totalSeconds + "秒";
                }
            }, 1 * 1000);
        },
        checkIsLogin() {
            let user_id = getAppUserId()
            let open_id = getOpenId()
            let login_token = getAppLoginToken()
            let login = false
            if (user_id && user_id != '' && open_id && open_id != '' && login_token && login_token != '') {
                login = true
            }
            return login
        },

        async doShowPurchaseDialog() {
            let current_time = new Date().valueOf() / 1000
            let reuslt = await getMyPurchases(getAppUserId(), current_time)
            let orderSize = reuslt.data.code == 0 ? reuslt.data.data.length : 0
            if (orderSize <= 1) {
                this.purchaseSuccessHint = '你已经成功购买第一个课程，请前往“智能排课-时间安排”设置小朋友的收听时间。'
                this.showScheduleTabIndex = 1
            } else {
                this.purchaseSuccessHint = '你已经成功购买此课程，（共' + this.totalAudioCounts + '个音频），请前往“智能排课-内容安排”设置每次收听的音频数量吧。'
                this.showScheduleTabIndex = 2
            }
            this.showPurchaseSuccessDialog = true
        },

        doGroupPurchase() {
            let loadingInstance = Loading.service({
                fullscreen: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.8)",
            });
            let price = this.groupPayPoints
            particGroupPurchase(getAppUserId(), this.groupPurchaseId, price).then((res) => {
                loadingInstance.close()
                let result = res.data
                if (result.code == 0) {
                    this.$message.success('拼团成功')
                    this.doShowPurchaseDialog()
                } else {
                    this.$message.error(result.msg)
                }
            })
        },

        checkGroupPurchase() {

            if (!this.checkIsLogin()) {
                this.$message.warning('请先登录')
                this.showLoginDialog = true
                return
            }
            //查看是否已经参与过了
            let enablePartic = true
            let partic_users = this.groupPruchaseInfo.partic_users
            if (partic_users && partic_users.length > 0) {
                for (let i = 0; i < partic_users.length; i++) {
                    if (partic_users[i] == getAppUserId()) {
                        enablePartic = false
                        break;
                    }
                }
            }
            if (enablePartic == false) {
                this.$message.error('您已经参与此拼团活动～')
                return
            }

            let price = this.groupPayPoints
            if (this.userPoints > price) {
                this.showGroupPurchaseDialog = true
            } else {
                this.pointsNotEnoughTitle = '拼团购买'
                this.pointsNotEnoughContent = '拼团购买消耗' + price + '可能点，当前可能点余额不足，请先购买可能点。'
                this.showPointsNotEnoughDialog = true
            }
        },

        fetchData() {
            getGroupPurchaseInfo(this.groupPurchaseId).then((res) => {
                let result = res.data.data
                this.groupPruchaseInfo = result
                let deadline = result.deadline
                let currentTime = new Date().valueOf() / 1000
                if (deadline <= (currentTime - 5 * 60)) {//提前5分钟结束
                    this.$message.error('拼团已经结束')
                    this.gotoPackageDetail()
                } else if (result.originator == getAppUserId()) {
                    this.gotoPackageDetail()
                } else {
                    this.requestCollectionInfo()
                    this.doStartGroupPurchaseCountDown()
                }
            })
        },

        async requestCollectionInfo() {
            let loadingInstance = Loading.service({
                fullscreen: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.8)",
            });
            let collectionResult = undefined
            if (this.checkIsLogin()) {
                collectionResult = await getCollection(this.groupPruchaseInfo.content_id)
            } else {
                collectionResult = await getCollectionV1(this.groupPruchaseInfo.content_id)
            }
            let havePurchasePackage = false
            this.collectionInfo = collectionResult.data.data;
            this.collectionInfo.album_contents.forEach((album) => {
                this.totalAudioCounts =
                    this.totalAudioCounts + album.album_songs.length;
            });
            this.totalKeshi = parseInt(Math.ceil(this.totalAudioCounts / 5));
            this.totalWeeks = parseInt(Math.ceil(this.totalAudioCounts / (5 * 2)));

            let collection_purchase_available =
                this.collectionInfo.collection_purchase_available;

            if (collection_purchase_available == "YES") {
                let collection_price = this.collectionInfo.actual_collection_price;
                let purchase_status = this.collectionInfo.purchase_status;
                if (collection_price > 0 && purchase_status == false) {
                    havePurchasePackage = false;
                } else {
                    havePurchasePackage = true;
                }
            } else {
                havePurchasePackage = true;
            }
            loadingInstance.close()
            if (havePurchasePackage) {
                this.gotoPackageDetail()
                return
            } else {
                let checkResult = await checkUserHasApplyCollection(getAppUserId(), this.groupPruchaseInfo.content_id, 'collection')
                this.formatPayPoints(checkResult.data.data != null)
            }
            if (this.checkIsLogin()) {
                let enablePartic = true
                let partic_users = this.groupPruchaseInfo.partic_users
                if (partic_users && partic_users.length > 0) {
                    for (let i = 0; i < partic_users.length; i++) {
                        if (partic_users[i] == getAppUserId()) {
                            enablePartic = false
                            break;
                        }
                    }
                }
                if (enablePartic == false) {
                    this.$message.error('您已经参与此拼团活动～')
                    this.gotoPackageDetail()
                    return
                }
            }
            this.formartReductionPrice()
        },

        formartReductionPrice() {
            this.reductionPriceVaule = parseInt(((this.collectionInfo.collection_price_show - this.groupPayPoints) / this.collectionInfo.collection_price_show) * 100) + '%'
        },

        requestUserpoints() {
            getUserByUserID(getAppUserId()).then((res) => {
                if (res.data.code == 0) {
                    this.userPoints = res.data.data.keneng_points;
                }
            });
        },

        doStartGroupPurchaseCountDown() {
            let that = this
            let currentTime = new Date().valueOf() / 1000
            let totalSeconds = parseInt(this.groupPruchaseInfo.deadline - currentTime)
            this.startGroupPurchaseCountDownTimer = setInterval(() => {
                totalSeconds--
                if (totalSeconds < 0) {
                    clearInterval(that.startGroupPurchaseCountDownTimer)
                    that.$message.warning('拼团结束')
                    that.notifyFinishGroupPurchase()
                    that.gotoPackageDetail()
                }
                that.groupPurchaseCountDownText = '距离结束：' + that.secondToTime(totalSeconds)
            }, 1 * 1000)
        },
        notifyFinishGroupPurchase() {
            finishGroupPurchase(this.groupPruchaseInfo._id.$id).then((res) => {

            })
        },
        secondToTime(s) {
            var t = '';
            var hour = Math.floor(s / 3600)
            var min = Math.floor(s / 60) % 60
            var sec = s % 60
            if (hour < 10) {
                t = '0' + hour + ":"
            } else {
                t = hour + ":"
            }
            if (min < 10) {
                t += "0"
            }
            t += min + ":"
            if (sec < 10) {
                t += "0"
            }
            t += parseInt(sec)
            return t
        },
        gotoPackageDetail() {
            this.$router.replace({
                path: '/app-package',
                query: {
                    id: this.groupPruchaseInfo.content_id
                }
            })
        },
        checkCollectionCover() {
            let albumContents = this.collectionInfo.album_contents;
            if (albumContents && albumContents.length < 4) {
                return 1;
            }
            let enbale = 0;
            for (let i = 0; i < 4; i++) {
                let album = albumContents[i];
                if (!album.cover_pic_url) {
                    enbale = 1;
                    break;
                }
                return enbale;
            }
        },
        formatProgress(content) {
            let album_no_answer_count = content.album_no_answer_count;
            let album_songs = content.album_songs.length;
            let answer_count = album_songs - album_no_answer_count;
            let progress = parseInt((answer_count / album_songs) * 100);
            return progress;
        },

        goBack() {
            this.$router.replace('/app-home')
        },
        gotoPurchasePoints() {
            this.$router.push({
                path: '/app-products'
            })
            this.showPointsNotEnoughDialog = false
        },

        gotoScheduleTab() {
            setHomeTabIndex(0)
            setHomeFragmentScheduleTabIndex(this.showScheduleTabIndex)
            this.goBack()
        },

        formatPayPoints(add) {
            if (add) {
                this.groupPayPoints = this.collectionInfo.actual_collection_price * 1.25 * 0.75
                this.groupPayPoints = this.groupPayPoints.toFixed(2)

                this.normalPayPoints = this.collectionInfo.actual_collection_price * 1.25
                this.normalPayPoints = this.normalPayPoints.toFixed(2)
            } else {

                this.groupPayPoints = this.collectionInfo.actual_collection_price * 0.75
                this.groupPayPoints = this.groupPayPoints.toFixed(2)

                this.normalPayPoints = this.collectionInfo.actual_collection_price * 1
                this.normalPayPoints = this.normalPayPoints.toFixed(2)
            }
        },
    },
    beforeDestroy() {
        if (this.startGroupPurchaseCountDownTimer) {
            clearInterval(this.startGroupPurchaseCountDownTimer)
        }
    }
})
</script>


<style lang="less" scoped>
.login-code {
    right: 28px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    position: absolute;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #5d5bb5;
}

.btn-groupPurchase {
    margin-top: 10px;
    cursor: pointer;
    height: 42px;
    line-height: 42px;
    text-align: center;
    color: white;
    width: 85%;
    box-sizing: border-box;
    background-color: #ff5e45;
    border-radius: 8px;
    font-size: 14px;
}

.bottom-left-container {
    margin-top: 10px;
    width: 85%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: white;
    border: 1px solid orange;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 14px;
}

.album-item {
    cursor: pointer;
    display: flex;
    margin-bottom: 25px;
}

.tag1-style {
    color: white;
    font-size: 12px;
    padding: 4px 8px;
    box-sizing: border-box;
    margin-right: 10px;
    margin-bottom: 8px;
    background-color: rgba(0, 0, 0, 0.38);
    border-radius: 14px;
}

.tag-style {
    color: white;
    font-size: 12px;
    padding: 4px 8px;
    box-sizing: border-box;
    margin-right: 10px;
    margin-bottom: 8px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
}

.package-detail-container {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-color: white;

    .top {
        position: relative;
        width: 100%;
        height: 400px;
        background: linear-gradient(135deg, #727bc9 0%, #5753b0 100%);
    }

    .package-content {
        position: absolute;
        margin-top: 280px;
        width: 100%;
        height: calc(100vh - 280px);
        background: white;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding: 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
}

.fistImg {
    width: 110px;
    height: 110px;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 12px;
}

.collectionImg {
    display: flex;
    flex-wrap: wrap;
    width: 110px;
    height: 110px;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 12px;
    box-sizing: border-box;
    flex-shrink: 0;

    img {
        width: 50%;
        height: 50%;
    }

    img:nth-child(1) {
        border-radius: 12px 0 0 0;
    }

    img:nth-child(2) {
        border-radius: 0 12px 0 0;
    }

    img:nth-child(3) {
        border-radius: 0 0 0 12px;
    }

    img:nth-child(4) {
        border-radius: 0 0 12px 0;
    }
}
</style>
